import React from 'react';

import { Meta } from '../components/Meta';
import { MainLayout } from '../components/MainLayout';
import { HeroSection } from '../components/HeroSection';
import { ServiceDetailsSection } from '../components/ServiceDetailsSection';
import HeroImage from '../images/trees.webp';
import TreePlanting from '../images/tree-planting.webp';
import BrushRemoval from '../images/brush-removal.webp';
import HedgeTrimming from '../images/hedge-trimming.webp';

const heroTitle = "Nantucket's Tree & Hedge Experts";
const heroSubtitle = 'Servicing Nantucket for over 20  years.';

const serviceTitle = 'Merlos Trees & Hedges';
const serviceSubtitle =
  'Merlos handles everything from tree and brush removal to tree moving and planting to hedge trimming and design. We will make your landscape beautiful for years to come.';
const services = [
  {
    title: 'Tree Planting',
    description:
      'Merlos tree experts will recommend and plant the best trees and sizes for your property. We can provide anything from saplings to mature trees — we have experience with all varieties.',
    image: TreePlanting,
  },
  {
    title: 'Brush Removal',
    description:
      'Our equipment can handle all of your landscaping needs. If you are looking to remove trees, bushes or cut back new growth, we have the staff and equipment to help you.',
    image: BrushRemoval,
  },
  {
    title: 'Tree Planting',
    description:
      'Our hedging experts will make your property look stunning with perfectly cut hedges and expert landscaping design.',
    image: HedgeTrimming,
  },
];

export default function TreesPage() {
  return (
    <MainLayout>
      <Meta route="trees" />
      <HeroSection title={heroTitle} subtitle={heroSubtitle} image={HeroImage} />
      <ServiceDetailsSection title={serviceTitle} subtitle={serviceSubtitle} services={services} />
    </MainLayout>
  );
}
